import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Cards() {
  const { ref: firstBoxRef, inView: firstBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: secondBoxRef, inView: secondBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const firstBoxControls = useAnimation();
  const secondBoxControls = useAnimation();

  React.useEffect(() => {
    if (firstBoxInView) {
      firstBoxControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      firstBoxControls.start({ x: -100, opacity: 0 });
    }
  }, [firstBoxInView, firstBoxControls]);

  React.useEffect(() => {
    if (secondBoxInView) {
      secondBoxControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      secondBoxControls.start({ x: 100, opacity: 0 });
    }
  }, [secondBoxInView, secondBoxControls]);

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-[#284b5a] ">
      <div
        className="main-container max-w-full h-auto relative mx-auto"
        style={{ maxWidth: "1280px" }}
      >
        <motion.div
          ref={firstBoxRef}
          animate={firstBoxControls}
          initial={{ x: -100, opacity: 0 }}
          className="flex w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl h-auto p-4 flex-col gap-4 justify-center items-center bg-[#d9d9d9] rounded-lg relative"
        >
          <div className="flex flex-col gap-4  relative z-[1]">
            <h2 className="text-2xl font-bold text-[#000] relative text-left">
              WKA-VERKLARING
            </h2>
            <p className="text-lg font-bold text-[#000] relative text-left">
              Mocht je als uitzendonderer zelf de verloning van uitzendkrachten
              doen én je backoffice niet uitbesteden, dan moet je loonheffing
              afdragen aan de Belastingdienst. Om de opdrachtgevers te
              verzekeren dat jij dit keurig ieder kwartaal doet, kun je een
              WKA-verklaring (Wet Keten Aansprakelijkheid) aanvragen. De
              Belastingdienst verstrekt dan eenmaal in de 3 maanden een
              verklaring dat jij aan je betalingsverplichtingen hebt voldaan.
              Deze verklaring toont aan dat het bedrijf alle omzetbelasting en
              loonheffingen over de voorgaande periode heeft betaald.
            </p>
          </div>
        </motion.div>
        <motion.div
          ref={secondBoxRef}
          animate={secondBoxControls}
          initial={{ x: 100, opacity: 0 }}
          className="flex w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl h-auto p-4 flex-col gap-4 justify-center items-center bg-[#d9d9d9] rounded-lg relative mt-8 ml-auto"
        >
          <div className="flex flex-col gap-4  relative z-[1]">
            <h2 className="text-2xl font-bold text-[#000] relative text-left">
              G-REKENING
            </h2>
            <p className="text-lg font-bold text-[#000] relative text-left">
              Voor afdrachten van loonbelasting en sociale premies wordt vaak
              gewerkt met een zogenaamde G-rekening. Een G-rekening is een
              geblokkeerde rekening die de opdrachtgever de zekerheid geeft dat
              je bent gevrijwaard van aansprakelijkheid voor betaling van
              loonbelasting en sociale premies. Als jij zorgt dat minimaal 30
              procent van het factuurbedrag op de G-rekening wordt gestort, ben
              jij gelijk ook gevrijwaard van aanslagen achteraf.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Cards;
