import React from "react";
import { motion } from "framer-motion";

const Welcome = ({ videoSource, altText, heading, text }) => {
  return (
    <div className="relative h-screen overflow-hidden">
      <video
        autoPlay
        muted
        loop
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src={videoSource} type="video/mp4" />
        {altText}
      </video>
      <div className="absolute inset-0 bg-[#284B5A] bg-opacity-70"></div>
      <div className="absolute inset-0 flex items-center justify-center text-center">
        <div className="text-white p-6 max-w-3xl">
          <motion.h1
            className="text-5xl font-bold mb-6 leading-tight"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {heading}
          </motion.h1>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {text}
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
