import React from "react";

const CardImage = ({ src, alt }) => {
  return (
    <div
      className="flex justify-center items-center w-full h-auto"
      style={{ backgroundColor: "#D9D9D9" }}
    >
      <img
        src={src}
        alt={alt}
        className="max-w-full h-auto"
        style={{ width: "auto", height: "auto" }}
      />
    </div>
  );
};

export default CardImage;
