import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/De Ploeg op de Root Background Image.png";
import imageUrl from "../../assets/images/De Ploeg op de Root 1.png";
import imageUrl2 from "../../assets/images/De Ploeg op de Root 2.png";

function DePloegopdeRoot() {
  const welcomeHeading = "DE PLOEG OP DE ROOT";

  const imageTextText = (
    <>
      De DD-Group heeft de ambitie om een belangrijke speler te worden binnen de
      uitzendbranche en recruitment maar wel op een verantwoordelijke en gezonde
      manier. Een belangrijk onderdeel van deze ambitie is zichtbaarheid en
      aanwezigheid op beurzen, social media, netwerkbijeenkomsten etc.. Binnen
      deze ambitie van hebben wij in 2022 een geweldige kans gekregen om
      hoofdsponsor te worden van het Isorex-No Aqua Ladies Cycling Team. Na een
      bewogen jaar zijn de ambities versterkt met een uitbreiding van de ploeg
      en het aantrekken van twee grote nieuwe sponsors.
    </>
  );

  const imageTextText2 = (
    <>
      Bent u op zoek naar de juiste kandidaat voor een positie binnen uw
      bedrijf, wij kunnen u hierbij helpen. Wij beschikken over een uitgebreide
      database van specialisten, variërend van recent afgestudeerde kandidaten
      tot ervaren krachten. Of het nu gaat om een productiemedewerker,
      pijpfitter, mechanical supervisor of procurement lead wij vinden de
      geschikte kandidaat voor U.
    </>
  );

  const backgroundColor = "#D9D9D9";
  const textColor = "black";

  return (
    <div>
      <Helmet>
        <title>Ploeg Op De Root</title>
        <meta
          name="description"
          content="Bij DD-Group zijn we toegewijd aan het bevorderen van de wervingsindustrie met ethische praktijken. Lees over onze samenwerking met het Isorex-No Aqua Dameswielerteam en hoe onze uitgebreide database je kan helpen de ideale kandidaat voor jouw behoeften te vinden."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <section>
          <WelcomeImage
            backgroundImage={backgroundImage}
            heading={welcomeHeading}
          />
          <ImageText1 imageUrl={imageUrl} text={imageTextText} />
          <ImageText2
            imageUrl={imageUrl2}
            text={imageTextText2}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default DePloegopdeRoot;
