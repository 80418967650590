import React, { useEffect } from "react";

function Linkedin() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widgets.sociablekit.com/linkedin-page-posts/widget.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="sk-ww-linkedin-page-post p-8 py-12 mt-16"
      data-embed-id="25456219"
    ></div>
  );
}

export default Linkedin;
