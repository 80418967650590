import React, { useState } from "react";
import { MdUpload } from "react-icons/md";

function Contact() {
  const [formData, setFormData] = useState({
    voornaam: "",
    achternaam: "",
    telefoonnummer: "",
    postcode: "",
    email: "",
    woonplaats: "",
    typeKlus: "",
    vrijeTekst: "",
    pdfFile: null,
  });

  const [pdfFileName, setPdfFileName] = useState(""); // State for file name
  const [responseMessage, setResponseMessage] = useState("");
  const [errors, setErrors] = useState({}); // State for validation errors

  // Regex patterns for validation
  const phonePattern = /^\+\d{1,3}\d{4,14}$/; // Example: +31xxxxxx
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, pdfFile: file }));
    setPdfFileName(file ? file.name : ""); // Update file name state
  };

  const validateForm = () => {
    const errors = {};
    if (!phonePattern.test(formData.telefoonnummer)) {
      errors.telefoonnummer = "Invalid phone number. Use format: +31xxxxxx";
    }
    if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Exit if validation fails
    }

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });

    try {
      const response = await fetch("http://localhost:5000/send", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setResponseMessage("Email sent successfully!");

        // Reset form after successful submission
        setFormData({
          voornaam: "",
          achternaam: "",
          telefoonnummer: "",
          postcode: "",
          email: "",
          woonplaats: "",
          typeKlus: "",
          vrijeTekst: "",
          pdfFile: null,
        });
        setPdfFileName(""); // Clear the file name state
        setErrors({}); // Clear errors
      } else {
        setResponseMessage("Failed to send email.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage("An error occurred while sending the email.");
    }
  };

  return (
    <div className="flex justify-center items-center p-5">
      <form className="w-full max-w-4xl" onSubmit={handleSubmit}>
        <div className="border-4 border-[#284B5A] rounded-lg p-6">
          <div className="flex flex-wrap gap-5">
            <div className="flex flex-col w-full lg:w-6/12">
              {/* Form fields */}
              <div className="flex flex-col lg:flex-row gap-5 mb-5">
                <div className="w-full lg:w-1/2">
                  <label className="block text-xl font-bold text-slate-500">
                    Voornaam
                  </label>
                  <input
                    type="text"
                    name="voornaam"
                    value={formData.voornaam}
                    onChange={handleChange}
                    className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="block text-xl font-bold text-slate-500">
                    Achternaam
                  </label>
                  <input
                    type="text"
                    name="achternaam"
                    value={formData.achternaam}
                    onChange={handleChange}
                    className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-5 mb-5">
                <div className="w-full lg:w-1/2">
                  <label className="block text-xl font-bold text-slate-500">
                    Telefoonnummer
                  </label>
                  <input
                    type="tel"
                    name="telefoonnummer"
                    value={formData.telefoonnummer}
                    onChange={handleChange}
                    className={`mt-2 w-full bg-white rounded-xl border ${
                      errors.telefoonnummer
                        ? "border-red-500"
                        : "border-slate-700"
                    } h-[68px] p-2`}
                  />
                  {errors.telefoonnummer && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.telefoonnummer}
                    </p>
                  )}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="block text-xl font-bold text-slate-500">
                    Postcode
                  </label>
                  <input
                    type="text"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                    className={`mt-2 w-full bg-white rounded-xl border ${
                      errors.postcode ? "border-red-500" : "border-slate-700"
                    } h-[68px] p-2`}
                  />
                  {errors.postcode && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.postcode}
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-5">
                <label className="block text-xl font-bold text-slate-500">
                  E-mail adres
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-2 w-full bg-white rounded-xl border ${
                    errors.email ? "border-red-500" : "border-slate-700"
                  } h-[68px] p-2`}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div className="mb-5">
                <label className="block text-xl font-bold text-slate-500">
                  Woonplaats
                </label>
                <input
                  type="text"
                  name="woonplaats"
                  value={formData.woonplaats}
                  onChange={handleChange}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                />
              </div>
              <div className="mb-5">
                <label className="block text-xl font-bold text-slate-500">
                  Type klus:
                </label>
                <input
                  type="text"
                  name="typeKlus"
                  value={formData.typeKlus}
                  onChange={handleChange}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                />
              </div>
              <div>
                <label className="block text-xl font-bold text-slate-500">
                  Vrije tekst met wensen en eventuele opmerkingen
                </label>
                <textarea
                  name="vrijeTekst"
                  value={formData.vrijeTekst}
                  onChange={handleChange}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[196px] p-2"
                />
              </div>
            </div>

            <div className="flex flex-col w-full lg:w-5/12 lg:mt-60 lg:ml-auto lg:items-center">
              <div className="text-xl font-bold text-center text-slate-500 mb-5">
                Wanneer wilt u de klus laten uitvoeren?
                <br />
                Welke dag komt u het beste uit om onze collega
                <br /> langs te sturen voor kennismaking en opname van de klus?
              </div>
              <div className="text-xl font-bold text-center text-slate-500 mb-4">
                Uploaden eventuele tekeningen, handleidingen (pdf)
              </div>
              <div className="relative w-full">
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div className="bg-white rounded-xl border border-dashed border-slate-700 h-[223px] w-full p-2 flex items-center justify-center cursor-pointer">
                  <MdUpload className="text-4xl text-slate-500" />
                </div>
                <div className="text-xl font-bold text-slate-500 mt-2">
                  {pdfFileName
                    ? `Selected file: ${pdfFileName}`
                    : "No file selected"}
                </div>
              </div>
            </div>
          </div>

          {responseMessage && (
            <p className="mt-4 text-center text-slate-500">{responseMessage}</p>
          )}

          {/* Submit Button */}
          <div className="flex justify-center mt-5">
            <button
              type="submit"
              className="bg-[#284B5A] text-white px-5 py-3 rounded-lg hover:bg-[#1d3a45] transition-colors duration-300"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;
