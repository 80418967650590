import React, { useState } from "react";
import gifSource from "../assets/images/hand.gif";
import { MdUpload } from "react-icons/md";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    voornaam: "",
    achternaam: "",
    telefoonnummer: "",
    postcode: "",
    email: "",
    woonplaats: "",
    typeKlus: "",
    vrijeTekst: "",
    pdfFile: null,
  });

  const [pdfFileName, setPdfFileName] = useState(""); // State for file name
  const [responseMessage, setResponseMessage] = useState("");
  const [errors, setErrors] = useState({}); // State for validation errors

  // Regex patterns for validation
  const phonePattern = /^\+\d{1,3}\d{4,14}$/; // Example: +31xxxxxx
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, pdfFile: file }));
    setPdfFileName(file ? file.name : ""); // Update file name state
  };

  const validateForm = () => {
    const errors = {};
    if (!phonePattern.test(formData.telefoonnummer)) {
      errors.telefoonnummer = "Invalid phone number. Use format: +31xxxxxx";
    }
    if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Exit if validation fails
    }

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });

    try {
      const response = await fetch("http://localhost:5000/send", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setResponseMessage("Email sent successfully!");

        // Reset form after successful submission
        setFormData({
          voornaam: "",
          achternaam: "",
          telefoonnummer: "",
          postcode: "",
          email: "",
          woonplaats: "",
          typeKlus: "",
          vrijeTekst: "",
          pdfFile: null,
        });
        setPdfFileName(""); // Clear the file name state
        setErrors({}); // Clear errors
      } else {
        setResponseMessage("Failed to send email.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage("An error occurred while sending the email.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-white p-8 py-12 mt-16">
      <div className="w-full max-w-5xl bg-white shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row">
        {/* Left Part - Form */}
        <div className="w-full lg:w-1/2 p-8">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="voornaam"
                  className="block text-sm font-medium text-gray-700"
                >
                  Voornaam
                </label>
                <input
                  type="text"
                  id="voornaam"
                  name="voornaam"
                  value={formData.voornaam}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="achternaam"
                  className="block text-sm font-medium text-gray-700"
                >
                  Achternaam
                </label>
                <input
                  type="text"
                  id="achternaam"
                  name="achternaam"
                  value={formData.achternaam}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="telefoonnummer"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telefoonnummer
                </label>
                <input
                  type="text"
                  id="telefoonnummer"
                  name="telefoonnummer"
                  value={formData.telefoonnummer}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="postcode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Postcode
                </label>
                <input
                  type="text"
                  id="postcode"
                  name="postcode"
                  value={formData.postcode}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-mail adres
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="woonplaats"
                className="block text-sm font-medium text-gray-700"
              >
                Woonplaats
              </label>
              <input
                type="text"
                id="woonplaats"
                name="woonplaats"
                value={formData.woonplaats}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="typeKlus"
                className="block text-sm font-medium text-gray-700"
              >
                Type klus
              </label>
              <input
                type="text"
                id="typeKlus"
                name="typeKlus"
                value={formData.typeKlus}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="vrijeTekst"
                className="block text-sm font-medium text-gray-700"
              >
                Vrije tekst met wensen en eventuele opmerkingen
              </label>
              <textarea
                id="vrijeTekst"
                name="vrijeTekst"
                rows="4"
                value={formData.vrijeTekst}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              ></textarea>
            </div>
            <div className="mt-4">
              <label
                htmlFor="cv"
                className="block text-sm font-medium text-gray-700"
              >
                Uploaden CV (pdf)
              </label>
              <label
                htmlFor="cv"
                className="bg-white rounded-xl border border-dashed border-slate-700 h-[223px] w-full p-2 flex items-center justify-center cursor-pointer"
              >
                <MdUpload className="text-4xl text-slate-500" />
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
              <div className="mt-2 text-sm text-[#284b5a]">
                {pdfFileName
                  ? `Selected file: ${pdfFileName}`
                  : "No file selected"}
              </div>
            </div>
            <button
              type="submit"
              className="mt-6 px-4 py-2 bg-[#284b5a] text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </form>
          {responseMessage && (
            <div className="mt-4 text-left text-sm font-medium text-[#284b5a]">
              {responseMessage}
            </div>
          )}
        </div>

        {/* Right Part - GIF and Text */}
        <div className="w-full lg:w-1/2 bg-[#284b5a] text-white p-8 flex flex-col justify-center">
          <div className="mb-4">
            <img
              src={gifSource}
              alt="Hand movement"
              className="w-full h-auto mb-4"
            />
          </div>
          <h1 className="text-2xl font-bold mb-4 text-center">
            Solliciteer nu!
          </h1>
          <p className="text-sm mb-4 text-center">
            Bent u op zoek naar een nieuwe uitdaging en denkt u dat u een
            waardevolle aanvulling bent voor één van onze opdrachtgevers?
            <br />
            <br />
            Wacht dan niet langer en solliciteer vandaag nog!
            <br />
            <br />
            Door de lege regels links van deze tekst in te vullen, kunt u uw cv
            en motivatiebrief uploaden en uw kans grijpen op een baan bij onze
            dynamische en groeiende organisatie. Wij kijken ernaar uit om uw
            sollicitatie te ontvangen en de mogelijkheid te hebben om kennis met
            u te maken.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
