import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image from "../assets/images/DD_Group_Footer_logo.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import IT_hub_Logo from "../assets/images/IT_Sol_Hub_logo.png";
import { FaPhoneVolume } from "react-icons/fa6";
import { TbWorldWww } from "react-icons/tb";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    } else {
      controls.start({ opacity: 0, x: -100 });
    }
  }, [controls, inView]);

  return (
    <footer ref={ref} className="bg-[#284B5A] py-8">
      <Container>
        {/* Footer Columns */}
        <Row className="mb-8">
          {/* First Column - Logo */}
          <Col md={4} className="d-flex align-items-center mb-4 md:mb-0">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.6 }}
            >
              <img src={image} alt="Logo" className="w-80 h-auto" />
            </motion.div>
          </Col>
          {/* Second Column */}
          <Col md={3} className="mb-4 md:mb-0">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <ul className="list-unstyled">
                <li className="text-white focus:outline-none  mb-2">
                  Hoofdkantoor
                </li>
                <li className="text-white focus:outline-none mb-2">
                  Laan van Nieuw Blankenburg 10-a 3181 DA Rozenburg
                </li>
              </ul>
            </motion.div>
          </Col>
          {/* Third Column */}
          <Col md={3} className="mb-4 md:mb-0">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <ul className="list-unstyled">
                <li className="text-white focus:outline-none mb-2">
                  Belgisch Erkenningsnummer: VG 2159 / UC
                </li>
                <li className="text-white focus:outline-none mb-2">
                  KVK: 20143342
                </li>
                <li className="text-white focus:outline-none mb-2">
                  BTW: NL819758048B01
                </li>
              </ul>
            </motion.div>
          </Col>
          {/* Fourth Column */}
          <Col md={2} className="mb-4 md:mb-0">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <ul className="list-unstyled">
                <li className="text-gray-300 focus:outline-none  mb-2 flex items-center">
                  <TbWorldWww className="mr-2 size-6 " /> info@dd-group.nl
                </li>
                <li className="text-gray-300 focus:outline-none  mb-2 flex items-center">
                  <FaPhoneVolume className="mr-2" /> +31 6 53407081
                </li>
                <li className="text-gray-300 focus:outline-none  mb-2 flex items-center">
                  <FaPhoneVolume className="mr-2" />
                  +31 6 25308642
                </li>
              </ul>
            </motion.div>
          </Col>
        </Row>
        {/* Line Design */}
        <div className="border-t border-gray-300 my-4"></div>
        {/* Footer Links */}
        <Row className="mb-4 flex justify-between items-center">
          <Col className="flex space-x-4">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.6, delay: 0.8 }}
            >
              <ul
                className="list-disc list-inside flex space-x-4 "
                style={{ color: "white" }}
              >
                <li>
                  <a
                    href="/"
                    className="text-white hover:text-gray-800 no-underline text-xs"
                  >
                    THUIS
                  </a>
                </li>
                <li>
                  <a
                    href="/Wie-zijn-Wij"
                    className="text-white hover:text-gray-800 no-underline text-xs"
                  >
                    OVER ONS
                  </a>
                </li>
                <li>
                  <a
                    href="/Inschrijven"
                    className="text-white hover:text-gray-800 no-underline text-xs"
                  >
                    INSCHRIJVEN
                  </a>
                </li>
                <li>
                  <a
                    href="/Partners"
                    className="text-white hover:text-gray-800 no-underline text-xs"
                  >
                    PARTNERS
                  </a>
                </li>
              </ul>
            </motion.div>
          </Col>
          <Col className="flex justify-end space-x-4">
            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.6, delay: 0.8 }}
            >
              <a
                href="https://www.linkedin.com/company/den-doelder-recruitment/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <FaLinkedinIn size={24} />
              </a>
            </motion.div>

            <motion.div
              animate={controls}
              initial={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.6, delay: 1 }}
            >
              <a
                href="https://www.instagram.com/deploegopderoot/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <FaInstagram size={24} />
              </a>
            </motion.div>
          </Col>
        </Row>
        {/* Bottom Line */}
        <div className="border-t border-gray-300 my-2"></div>
        {/* Trademark */}
        <Row className="justify-content-between text-white focus:outline-none">
          <Col className="text-left">
            <small className="text-xs">
              &copy; DD-Group . All right reserved
            </small>
          </Col>
          <Col className="text-right">
            <a
              href="https://www.itsolutionsworldwide.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex justify-content-end align-items-center text-white no-underline"
            >
              <small className="text-xs">Developed by</small>
              <img src={IT_hub_Logo} alt="Logo" style={{ margin: "0 5px" }} />
              <small className="text-xs">IT SOLUTIONS WORLDWIDE</small>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
