import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ChevronRightIcon } from "@heroicons/react/24/solid"; // Import the ChevronRightIcon

const ImageText3 = ({
  imageUrl1,
  imageUrl2,
  heading,
  text,
  buttonText,
  buttonLink,
  backgroundColor = "#284B5A",
  textColor = "#fff",
  buttonColor = "#fff", // Default button color
  buttonTextColor = "#284B5A", // Default button text color
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.5, // Percentage of component visible to trigger the animation
  });

  return (
    <div style={{ backgroundColor }}>
      <div
        className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: "1310px" }}
        ref={ref}
      >
        <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-12">
          <motion.div
            className="w-full lg:w-2/3"
            initial={{ x: -100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <h2
              className="text-3xl lg:text-3xl font-semibold mb-4"
              style={{ color: textColor, fontWeight: "bold" }}
            >
              {heading}
            </h2>
            <p className="text-lg lg:text-lg" style={{ color: textColor }}>
              {text}
            </p>
            {buttonText && (
              <a
                href={buttonLink}
                className="inline-flex items-center no-underline mt-1 px-6 py-1 text-lg font-semibold rounded-lg shadow-md"
                style={{ color: buttonTextColor, backgroundColor: buttonColor }}
              >
                {buttonText}
                <ChevronRightIcon
                  className="ml-2 w-5 h-5"
                  style={{ color: buttonTextColor }}
                />
              </a>
            )}
          </motion.div>
          <div className="relative w-full lg:w-2/3 h-96">
            <motion.div
              className="absolute top-0 left-0 ml-5 md:ml-[100px] mt-10 w-1/2 h-1/2"
              initial={{ y: -100, opacity: 0 }}
              animate={inView ? { y: 0, opacity: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <img
                src={imageUrl1}
                alt="First"
                className="w-full h-full object-cover"
              />
            </motion.div>
            <motion.div
              className="absolute bottom-0 right-0 w-1/2 h-1/2"
              initial={{ y: 100, opacity: 0 }}
              animate={inView ? { y: 0, opacity: 1 } : {}}
              transition={{ duration: 0.5 }}
              style={{ transform: "translateY(-50%) translateX(50%)" }} // Adjust to overlap
            >
              <img
                src={imageUrl2}
                alt="Second"
                className="w-full h-full object-cover"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageText3;
