import React from "react";
import { motion } from "framer-motion";
import imageurl1 from "../../assets/images/Sponsor1.png";
import imageurl2 from "../../assets/images/Sponsor2.png";
import imageurl3 from "../../assets/images/Sponsor3.png";
import imageurl4 from "../../assets/images/Sponsor4.png";
import imageurl5 from "../../assets/images/Sponsor5.png";
import imageurl6 from "../../assets/images/Sponsor6.png";

const ImageCollage = () => {
  // Array of images, alternating left and right for animation
  const images = [
    { src: imageurl1, alt: "Image 1", direction: "left" },
    { src: imageurl2, alt: "Image 2", direction: "right" },
    { src: imageurl3, alt: "Image 3", direction: "left" },
    { src: imageurl4, alt: "Image 4", direction: "right" },
    { src: imageurl5, alt: "Image 5", direction: "left" },
    { src: imageurl6, alt: "Image 6", direction: "right" },
  ];

  // Animation variants for images
  const variants = {
    hidden: (direction) => ({
      x: direction === "left" ? -100 : 100,
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="bg-[#D9D9D9]">
      <div className="max-w-screen-lg mx-auto p-4 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="overflow-hidden rounded-lg shadow-lg"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              custom={image.direction}
              variants={variants}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCollage;
