import React, { useState } from "react";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    question: "",
  });
  const [message, setMessage] = useState(""); // State to handle success or error messages

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Email validation regex
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Phone number validation regex
  const validatePhone = (phone) => {
    const re = /^\+\d{1,3}\d{6,14}$/; // Adjust regex based on phone number format
    return re.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setMessage("Invalid email address format.");
      return;
    }

    if (!validatePhone(formData.phone)) {
      setMessage("Phone number must be in the format +31xxxxxxx.");
      return;
    }

    try {
      const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const result = await response.text();
      setMessage("Email sent successfully!");
      setFormData({ name: "", email: "", phone: "", question: "" }); // Reset form
    } catch (error) {
      setMessage("Failed to send email.");
    }
  };

  return (
    <div className="fixed bottom-5 right-5 z-50">
      {/* Chatbot Icon */}
      <button
        onClick={toggleChatbot}
        className="bg-[#284b5a] p-3 rounded-full shadow-lg text-white hover:bg-black"
      >
        <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6" />
      </button>

      {/* Contact Form */}
      {isOpen && (
        <div className="fixed bottom-16 right-5 bg-white p-6 rounded-lg shadow-lg w-80 border border-gray-200">
          <h2 className="text-lg font-bold mb-4">Stel Je Vraag!</h2>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Uw naam"
              className="border p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="E-mail adres"
              className="border p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Telefoonnummer"
              className="border p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
            <textarea
              name="question"
              value={formData.question}
              onChange={handleChange}
              placeholder="Jouw Vraag"
              className="border p-2 rounded h-24 focus:outline-none focus:ring focus:border-blue-300"
            />
            <button
              type="submit"
              className="bg-[#284b5a] text-white p-2 rounded mt-2 hover:bg-black"
            >
              Submit
            </button>
          </form>
          {message && (
            <p
              className={`mt-4 ${
                message.includes("successfully")
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {message}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Chatbot;
