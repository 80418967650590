import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ChevronRightIcon } from "@heroicons/react/24/solid"; // Import the ChevronRightIcon

const ImageText2 = ({
  imageUrl,
  heading,
  text1,
  text2,
  buttonText,
  buttonLink,
  logoUrl,
  backgroundColor = "#284B5A",
  textColor = "#fff",
  buttonColor = "#284B5A", // Default button color
  buttonTextColor = "#fff", // Default button text color
  enableZoom = false,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.5, // Percentage of component visible to trigger the animation
  });

  return (
    <div className={backgroundColor}>
      <div
        className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: "1310px" }}
        ref={ref}
      >
        <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-12">
          <motion.div
            className={`${
              enableZoom
                ? "h-full lg:h-2/3 relative overflow-hidden"
                : "w-full lg:w-1/2"
            }`}
            initial={{ x: -100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <motion.img
              src={imageUrl}
              alt="placeholder"
              className="w-full lg:w-full lg:h-auto object-contain"
              style={{ transformOrigin: "top left" }} // Set transform origin to top-left
              initial={enableZoom ? { scale: 1.3 } : { x: -100, opacity: 0 }} // Initial zoom in and pop in from left
              animate={inView ? { x: 0, opacity: 1, scale: 1 } : {}} // Animate to normal size and position
              transition={{ duration: 0.5 }}
              whileHover={enableZoom ? { scale: 1.1 } : {}} // Zoom out slightly on hover
            />
          </motion.div>
          <motion.div
            className="w-full lg:w-2/3"
            initial={{ x: 100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <h2
              className={`text-xl lg:text-3xl font-semibold ${textColor} leading-7 mt-4 lg:mt-0 mb-8`}
            >
              <span style={{ color: "#284B5A", fontWeight: "bold" }}>
                {heading}
              </span>
            </h2>
            <p className={`text-lg mt-1 lg:text-lg ${textColor} leading-7`}>
              {text1}
            </p>
            {buttonText && (
              <a
                href={buttonLink}
                className="inline-flex items-center no-underline my-2 px-6 py-1 text-lg font-semibold rounded-lg shadow-md"
                style={{ backgroundColor: buttonColor, color: buttonTextColor }}
              >
                {buttonText}
                <ChevronRightIcon
                  className="ml-2 w-5 h-5"
                  style={{ color: buttonTextColor }}
                />
              </a>
            )}
            <p className={`text-lg mt-1 lg:text-lg ${textColor} leading-7`}>
              {text2}
            </p>
          </motion.div>
        </div>
        {logoUrl && (
          <div className="flex justify-center mt-6">
            <img src={logoUrl} alt="Logo" className="w-32 h-auto" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageText2;
