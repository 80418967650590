import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ChevronRightIcon } from "@heroicons/react/24/solid"; // Import the ChevronRightIcon

const ImageText1 = ({
  imageUrl,
  heading,
  text,
  buttonText,
  buttonLink,
  logoUrl,
  backgroundColor = "#284B5A",
  textColor = "#fff",
  buttonColor = "#fff", // Default button color
  buttonTextColor = "#284B5A", // Default button text color
  openInNewTab = false, // Default to open in the same tab
  downloadFile, // New prop for file download
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.5, // Percentage of component visible to trigger the animation
  });

  return (
    <div style={{ backgroundColor }}>
      <div
        className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: "1310px" }}
        ref={ref}
      >
        <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-12">
          <motion.div
            className="w-full lg:w-2/3"
            initial={{ x: -100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <h2
              className="text-3xl lg:text-3xl font-semibold mb-4"
              style={{ color: textColor, fontWeight: "bold" }}
            >
              {heading}
            </h2>
            <p className="text-lg lg:text-lg" style={{ color: textColor }}>
              {text}
            </p>
            {buttonText && (
              <a
                href={downloadFile ? downloadFile : buttonLink} // Use the file URL if downloadFile is passed
                download={downloadFile ? true : undefined} // Set download attribute if downloadFile is passed
                target={openInNewTab && !downloadFile ? "_blank" : "_self"} // Open in new tab unless it's a file download
                rel={
                  openInNewTab && !downloadFile
                    ? "noopener noreferrer"
                    : undefined
                } // Secure external links
                className="inline-flex items-center no-underline mt-1 px-6 py-1 text-lg font-semibold rounded-lg shadow-md"
                style={{ color: buttonTextColor, backgroundColor: buttonColor }}
              >
                {buttonText}
                <ChevronRightIcon
                  className="ml-2 w-5 h-5"
                  style={{ color: buttonTextColor }}
                />
              </a>
            )}
            {logoUrl && (
              <div className="mt-4">
                <img src={logoUrl} alt="Logo" className="w-[150px] h-auto" />
              </div>
            )}
          </motion.div>
          <motion.div
            className="w-full lg:w-1/2"
            initial={{ x: 100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 0.5 }}
          >
            <img src={imageUrl} alt="placeholder" className="w-full h-auto" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ImageText1;
