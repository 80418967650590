import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function ImageTextp({ imageUrl, text }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.5, // Percentage of component visible to trigger the animation
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.8,
        },
      });
    } else {
      controls.start({ x: 100, opacity: 0 });
    }
  }, [inView, controls]);

  return (
    <motion.div
      ref={ref}
      initial={{ x: 100, opacity: 0 }}
      animate={controls}
      className="bg-white py-12 px-4 lg:ml-[250px]"
    >
      <div className="flex bg-[#284b5a] flex-wrap gap-10 justify-center items-center text-xl leading-9 text-white mx-auto py-12 px-4 sm:px-6 lg:px-8 max-w-[1000px]">
        <p className="self-stretch my-auto w-[526px] max-md:max-w-full">
          {text}
        </p>
        <img
          loading="lazy"
          src={imageUrl}
          alt="placeholder"
          className="object-contain self-stretch my-auto aspect-[1.01] min-w-[240px] w-[261px]"
        />
      </div>
    </motion.div>
  );
}

export default ImageTextp;
