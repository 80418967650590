import React from "react";
import { motion } from "framer-motion";

const Welcome = ({ backgroundImage, altText, heading, text }) => {
  return (
    <div className="relative h-screen">
      <img
        src={backgroundImage}
        alt={altText}
        className="background-image absolute inset-0 w-full h-full object-cover"
      />
      {/* Overlay with gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#284b5a] to-[#284b5a] opacity-50"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
        <div className="welcome-content mx-8 max-w-[1080px] p-8 sm:p-4 rounded-3xl">
          <div className="bg-[#D9D9D9] bg-opacity-100 p-4 rounded-md">
            <motion.h1
              className="text-3xl sm:text-4xl lg:text-5xl text-black font-bold mb-4"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {heading}
            </motion.h1>
            <motion.p
              className="text-sm sm:text-base lg:text-lg text-black font-normal mb-4"
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {text}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
