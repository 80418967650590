import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Welcome from "../../components/Welcome";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";
import Partners from "../home/Partners";
import videoSource from "../../assets/video/home_video_1.mp4";
import imageUrl from "../../assets/images/home_page_1.png";
import imageUrl2 from "../../assets/images/home_page_2.png";
import VideoText from "./VideoText";

function Home() {
  const welcomeHeading = "SAMEN OP WEG";
  const welcomeText =
    "Wij willen zowel de werknemer als de opdrachtgever boeien en langdurig binden door middel van zeer interessante deals voor iedereen persoonlijk of bedrijfsmatig afgestemd. Uw voordeel is ook ons voordeel, wij doen er alles aan om een win-win situatie te creëren.";

  const imageTextHeading = "WAT WIJ DOEN";
  const imageTextText = (
    <>
      DD-Group is een kwalitatief hoogstaande speler op de Belgische en
      Nederlandse (arbeids-)markt, met name in de segmenten techniek, logistiek
      en industrie. Zij heeft haar klanten in Zeeland, Limburg, Vlaanderen en
      rond de havens van Antwerpen, Gent, Terneuzen ,Vlissingen en Rotterdam.
      <br />
      <br />
      DD-Group Recruitment heeft een diversiteit aan HR diensten voor uw bedrijf
      beschikbaar. Wij onderscheiden ons in de markt door een persoonlijke en
      kwalitatief gedegen aanpak. Omdat we zelfstandig werken kent onze
      flexibiliteit geen grenzen.
      <br />
      <br />
      Vanwege onze erkenning in Vlaanderen (VG 2159/UC) plaatsen en werven we
      over de landsgrenzen van NL en BE. Daarmee bieden we flexibiliteit aan
      werkgevers & -nemers in de grensregio's.
    </>
  );

  const imageTextHeading2 = "DD-GROUP";
  const imageTextText2 = (
    <>
      Levert kwaliteit in mensen en opdrachten
      <br />
      <br />
      Is een organisatie gericht op het ontzorgen van de ZZP-ers en
      opdrachtgevers in de breedste zin van het woord.
      <br />
      <br />
      Voor ZZP-ers regelen wij bemiddeling bij nieuwe (langdurige) projecten,
      bieden opleidingen middels een gereserveerd opleidingspotje via gelieerde
      opleidingscentra en onze eigen mensen, zorgen voor administratieve
      ondersteuning middels een eigen administratiekantoor aangaande de
      facturering en gehele financiële administratie inclusief aangiftes en
      tevens verhuren wij materieel.
    </>
  );

  return (
    <div>
      <Helmet>
        <title>DD-Group</title>
        <meta
          name="description"
          content="Den Doelder Recruitment biedt topkwaliteit personeelsdiensten, volgens belangrijke branchecertificeringen en normen. We maken deel uit van verschillende brancheorganisaties en volgen de overheidseisen voor tijdelijk werk. Onze certificeringen omvatten SNA, VCU, KIWA en ABU, die ervoor zorgen dat we voldoen aan hoge normen voor veiligheid en betrouwbaarheid. We verstrekken ook WKA-verklaringen en beheren G-rekeningen voor een duidelijke loonbelastingadministratie. Neem contact met ons op via info@DD-Group.nl voor betrouwbare personeelsoplossingen."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <Welcome
          videoSource={videoSource}
          heading={welcomeHeading}
          text={welcomeText}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            heading={imageTextHeading}
            text={imageTextText}
          />
          <ImageText2
            imageUrl={imageUrl2}
            heading={imageTextHeading2}
            text={imageTextText2}
          />
          <Partners />
          <VideoText />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
