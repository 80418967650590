import React from "react";
import { Helmet } from "react-helmet";

import Header2 from "../../components/Header2";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
function Inschrijven() {
  return (
    <div>
      <Helmet>
        <title>Inschrijven</title>
        <meta
          name="description"
          content="Bezoek ons formulier om je CV te uploaden. We bekijken je inzending en nemen binnenkort contact met je op."
        />
      </Helmet>
      <header>
        <Header2 />
      </header>
      <main>
        <section>
          <ContactUs />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Inschrijven;
