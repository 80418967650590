import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Cards() {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: topBoxesRef, inView: topBoxesInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: bottomBoxRef, inView: bottomBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const headingControls = useAnimation();
  const topBoxesControls = useAnimation();
  const bottomBoxControls = useAnimation();

  React.useEffect(() => {
    if (headingInView) {
      headingControls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
    } else {
      headingControls.start({ y: -50, opacity: 0 });
    }
  }, [headingInView, headingControls]);

  React.useEffect(() => {
    if (topBoxesInView) {
      topBoxesControls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5, staggerChildren: 0.2 },
      });
    } else {
      topBoxesControls.start({ y: 50, opacity: 0 });
    }
  }, [topBoxesInView, topBoxesControls]);

  React.useEffect(() => {
    if (bottomBoxInView) {
      bottomBoxControls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
    } else {
      bottomBoxControls.start({ y: 50, opacity: 0 });
    }
  }, [bottomBoxInView, bottomBoxControls]);

  return (
    <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 bg-[#D9D9D9]">
      <div className="main-container max-w-full lg:max-w-[1000px] mx-auto">
        {/* Heading */}
        <motion.h2
          ref={headingRef}
          animate={headingControls}
          initial={{ y: -50, opacity: 0 }}
          className="text-center text-2xl sm:text-3xl md:text-4xl font-bold text-black mt-0 mb-4 sm:mb-6 lg:mb-8"
        >
          WIE ZIJN WIJ
        </motion.h2>
        <motion.h3
          ref={headingRef}
          animate={headingControls}
          initial={{ y: -50, opacity: 0 }}
          className="text-center text-lg sm:text-xl md:text-2xl font-bold text-black mb-8"
        >
          DD-Group levert kwaliteit in mensen en opdrachten
        </motion.h3>
        {/* Top Boxes */}
        <motion.div
          ref={topBoxesRef}
          animate={topBoxesControls}
          initial={{ y: 50, opacity: 0 }}
          className="flex flex-col lg:flex-row lg:justify-between lg:space-x-8 space-y-8 lg:space-y-0"
        >
          <div className="w-full lg:w-[350px] p-8 bg-[#284b5a] text-white">
            <p className="text-base leading-7 text-center">
              DD-Group is een kwalitatief hoogstaande speler op de Belgische en
              Nederlandse (arbeids-)markt, met name in de segmenten techniek,
              scheepvaart, logistiek en industrie. Zij heeft haar klanten in
              Nederland en België, van rond de havens van Antwerpen, Terneuzen
              ,Vlissingen, Amsterdam en Rotterdam tot aan de industrie rond
              Delfzijl.
            </p>
          </div>
          <div className="w-full lg:w-[350px] p-8 bg-[#284b5a] text-white">
            <p className="text-base leading-7 text-center">
              DD-Group is een organisatie gericht op het ontzorgen van de
              ZZP-ers en opdrachtgevers in de breedste zin van het woord. Voor
              ZZP-ers regelen wij bemiddeling bij nieuwe (langdurige) projecten
              en bieden wij, indien nodig, opleidingen aan via gelieerde
              opleidingscentra.
            </p>
          </div>
        </motion.div>
        {/* Bottom Box */}
        <motion.div
          ref={bottomBoxRef}
          animate={bottomBoxControls}
          initial={{ y: 50, opacity: 0 }}
          className="w-full lg:w-[350px] p-8 bg-[#284b5a] text-white mt-8 lg:mt-14 mx-auto"
        >
          <p className="text-base leading-7 text-center">
            Het aan ons verbonden administratie kantoor Den Doelder Recruitment
            heeft een diversiteit aan diensten voor uw bedrijf beschikbaar.
            Samen onderscheiden wij ons in de markt door een persoonlijke en
            kwalitatief gedegen aanpak. Omdat we zelfstandig werken kent onze
            flexibiliteit geen grenzen.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Cards;
