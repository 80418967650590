import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage1 from "../../components/WelcomeImage1";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/Certificeringen en Brancheverenigingen Background Image.png";
import imageUrl from "../../assets/images/Certificeringen en Brancheverenigingen 1.png";
import imageUrl1 from "../../assets/images/Certificeringen en Brancheverenigingen 2.png";
import imageUrl2 from "../../assets/images/Certificeringen en Brancheverenigingen 3.png";
import Cards from "./Cards";
import Split from "./Split";

function CertificeringenenBrancheverenigingen() {
  const welcomeHeading = <>DEN DOELDER RECRUITMENT</>;
  const welcometext = (
    <>
      Als arbeidsbemiddelaar krijg je te maken met diverse verplichte keurmerken
      en certificeringen waaraan je moet voldoen voordat je als onderneming
      arbeid ter beschikking mag stellen.
      <br />
      De DD-Group is via het verbonden administratie kantoor Den Doelder
      Recruitment lid van diverse brancheverenigingen en volgt de richtlijnen
      van de overheid welke gesteld worden aan de uitzendbranche.
    </>
  );
  const imageTextText = (
    <>
      Het SNA-keurmerk is het keurmerk voor alle ondernemingen die arbeid ter
      beschikking stellen zoals uitzendondernemingen, detacherings-en
      payrollbedrijven en voor (onder)aannemers van werk.SNA heeft als doel het
      realiseren van zelfregulering ter voorkoming van fraude en illegaliteit in
      de uitzendbranche en bij alle vormen van (onder)aanneming van werk.
    </>
  );
  const imageheading = <>SNA KEURMERK</>;
  const imageTextText1 = (
    <>
      De Veiligheid en Gezondheid Checklist Uitzendorganisaties is afgeleid van
      VCA. Het VCA is gericht op veiligheid, gezondheid en milieu (VGM). Het
      verkrijgen van deze certificering gaat alleen als je NEN-4400-1
      gecertificeerd bent.VCU is gericht op veiligheid en gezondheid. Hieraan is
      een VG-beheersysteem gekoppeld. Een uitzendbureau dat in bezit is van een
      VCU certificaat kan daarmee aantonen dat het in bezit is van een
      VG-beheersysteem. VCU gecertificeerde uitzendbureaus kunnen flexibel
      personeel beschikbaar stellen aan bedrijven in de techniek die VCA
      gecertificeerd zijn.De meeste VCA gecertificeerde bedrijven voeren
      werkzaamheden uit in een werkomgeving waarin het risico op letsel aanwezig
      is.
      <br />
      <br /> Werken in een risicovolle omgeving vereist een zorgvuldige houding
      en een bepaald kennisniveau van veiligheidsaspecten. Een risicovolle
      werkomgeving kan men onder andere aantreffen in de olie en gasindustrie,
      op de bouwplaats, in de scheepsbouw en fabrieken. Uitzendbureaus die
      personeel bemiddelen in deze werkgebieden doen er goed aan om zich VCU te
      laten certificeren. Niet alleen het uitzendbureau dient gecertificeerd te
      zijn ook de intercedenten en andere interne uitzendmedewerkers dienen een
      VCU certificaat te behalen. De flexwerkers die door het uitzendbureau
      worden bemiddelt dienen een VCA certificaat te behalen.
    </>
  );

  const imageheading2 = <>KIWA</>;
  const imageTextText2 = (
    <>
      Het SNA-keurmerk is het keurmerk voor alle ondernemingen die arbeid ter
      beschikking stellen zoals uitzendondernemingen, detacherings-en
      payrollbedrijven en voor (onder)aannemers van werk.SNA heeft als doel het
      realiseren van zelfregulering ter voorkoming van fraude en illegaliteit in
      de uitzendbranche en bij alle vormen van (onder)aanneming van werk.
    </>
  );
  const backgroundColor = "#D9D9D9";
  const textColor = "black";

  return (
    <div>
      <Helmet>
        <title>VCU/VCA/NEN Certificering</title>
        <meta
          name="description"
          content="Den Doelder Recruitment biedt topkwaliteit personeelsdiensten, volgens belangrijke branchecertificeringen en normen. We maken deel uit van verschillende brancheorganisaties en volgen de overheidseisen voor tijdelijk werk. Onze certificeringen omvatten SNA, VCU, KIWA en ABU, die ervoor zorgen dat we voldoen aan hoge normen voor veiligheid en betrouwbaarheid. We verstrekken ook WKA-verklaringen en beheren G-rekeningen voor een duidelijke loonbelastingadministratie. Neem contact met ons op via info@DD-Group.nl voor betrouwbare personeelsoplossingen."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage1
          heading={welcomeHeading}
          text={welcometext}
          backgroundImage={backgroundImage}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            heading={imageheading}
            text={imageTextText}
          />
          <ImageText2
            imageUrl={imageUrl1}
            text={imageTextText1}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
          <ImageText1
            imageUrl={imageUrl2}
            heading={imageheading2}
            text={imageTextText2}
          />
          <Cards />
          <Split />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default CertificeringenenBrancheverenigingen;
