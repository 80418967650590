import React from "react";
import { Helmet } from "react-helmet";

import Header2 from "../../components/Header2";
import Footer from "../../components/Footer";
import Linkedin from "../../components/Linkedin";
import InstagramFeed from "../../components/Instagram";
function SocialMediaLink() {
  return (
    <div>
      <Helmet>
        <title>Social Media Link</title>
        <meta
          name="description"
          content="Mis onze dagelijkse updates niet! Volg ons op sociale media via de aangegeven links en blijf verbonden."
        />
      </Helmet>
      <header>
        <Header2 />
      </header>
      <main>
        <section>
          <Linkedin />
          <InstagramFeed />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default SocialMediaLink;
