import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Welcome from "../../components/Welcome";
import ImageText1 from "../../components/ImageText1-1";
import ImageText2 from "../../components/ImageText2-1";
import Footer from "../../components/Footer";

import videoSource from "../../assets/video/industrie_video.mp4";
import imageUrl from "../../assets/images/industrie_image1.png";
import imageUrl2 from "../../assets/images/industrie_image2.png";

function BouwInfra() {
  const welcomeHeading = "SAMEN OP WEG";
  const welcomeText =
    "Wij willen zowel de werknemer als de opdrachtgever boeien en langdurig binden door middel van zeer interessante deals voor iedereen persoonlijk of bedrijfsmatig afgestemd. Uw voordeel is ook ons voordeel, wij doen er alles aan om een win-win situatie te creëren.";

  const imageTextHeading = "Interesse in een functie in de industriesector? ";
  const imageTextText = (
    <>
      Bent u geïnteresseerd in een mooie functie in de industrie en heeft u
      voldoende ervaring opgebouwd ?
      <br />
      <br />
      of wilt u juist ervaring opbouwen? Dan zijn wij naar u op zoek! Wij bieden
      u de mogelijkheid om via ?
      <br />
      <br />
      ons tewerkgesteld te worden bij diverse gerenommeerde organisaties binnen
      de industriesector..
      <br />
      <br />
      Heeft u interesse?
    </>
  );

  const buttonText1 = "Neem Contact Met Ons Op";
  const buttonLink1 = "/Inschrijven"; // Update with your actual link

  const imageTextHeading2 = "Wat houdt het in";
  const imageTextText2 = (
    <>
      Of u nu als flensmonteur, inspecteur, lasser of fitter, maar ook als
      werkvoorbereider, uitvoerder, of projectleider werkt of wilt gaan werken,
      aarzel niet en reageer vandaag nog! Het enige wat u hoeft te doen is u aan
      te melden via onze site en uw CV te uploaden. Na ontvangst van uw gegevens
      neemt één van onze medewerkers contact met u op voor een eerste
      kennismakingsgesprek. Als het duidelijk is in welke richting u het liefst
      werk zoekt gaan wij voor u aan de slag.
    </>
  );

  const imageTextText2p2 = <></>;

  const buttonText2 = "Cv Uploaden";
  const buttonLink2 = "/Inschrijven"; // Update with your actual link

  const colorbackground2 = "#D9D9D9";
  const colortext2 = "text-[#284B5A]";

  return (
    <div>
      <Helmet>
        <title>Industrie</title>
        <meta
          name="description"
          content="Ontdek geweldige carrières in de industriële sector met onze maatwerk vacatureplaatsingen. Upload vandaag nog je CV, en wij helpen je de perfecte rol te vinden, of je nu lasser, fitter, projectleider of iets anders bent!"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <Welcome
          videoSource={videoSource}
          heading={welcomeHeading}
          text={welcomeText}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            heading={imageTextHeading}
            buttonText={buttonText1} // Make sure the prop names match
            buttonLink={buttonLink1} // Pass buttonLink if needed
            text={imageTextText}
            enableZoom={true}
          />
          <ImageText2
            imageUrl={imageUrl2}
            heading={imageTextHeading2}
            buttonText={buttonText2} // Make sure the prop names match
            buttonLink={buttonLink2} // Pass buttonLink if needed
            text1={imageTextText2}
            text2={imageTextText2p2}
            background={colorbackground2}
            textColor={colortext2}
            enableZoom={true}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default BouwInfra;
