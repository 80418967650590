import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Welcome from "../../components/Welcome";
import ImageText1 from "../../components/ImageText1-1";
import Footer from "../../components/Footer";
import ImageText2 from "../../components/ImageText2-1";
import videoSource from "../../assets/video/bouwInfra_video.mp4";
import imageUrl from "../../assets/images/bouwInfra_image1.png";
import imageUrl2 from "../../assets/images/bouwInfra_image2.png";

function BouwInfra() {
  const welcomeHeading = "SAMEN OP WEG";
  const welcomeText =
    "Wij willen zowel de werknemer als de opdrachtgever boeien en langdurig binden door middel van zeer interessante deals voor iedereen persoonlijk of bedrijfsmatig afgestemd. Uw voordeel is ook ons voordeel, wij doen er alles aan om een win-win situatie te creëren.";

  const imageTextHeading =
    "Interesse in een functie in de bouw- en infrasector?";
  const imageTextText = (
    <>
      Bent u geïnteresseerd in een mooie functie in de bouw of infra en heeft u
      voldoende ervaring opgebouwd of wilt u juist ervaring opbouwen?
      <br />
      <br />
      Dan zijn wij naar u op zoek! Wij bieden u de mogelijkheid om via ons
      tewerkgesteld te worden bij diverse gerenommeerde organisaties binnen de
      bouw - en infrasector.
      <br />
      <br />
      Vanwege onze erkenning in Vlaanderen (VG 2159/UC) plaatsen en werven we
      over de landsgrenzen van NL en BE. Daarmee bieden we flexibiliteit aan
      werkgevers & -nemers in de grensregio's.
    </>
  );

  const buttonText1 = "Neem contact met ons op";
  const buttonLink1 = "/Inschrijven"; // Update with your actual link

  const imageTextHeading2 = "Wat betekent het";
  const imageTextText2 = (
    <>
      Of u nu als civiel engineer, timmerman, tekenaar of \ isolatiespecialist,
      maar ook als werkvoorbereider, uitvoerder, of kraanmachinist werkt of wilt
      gaan werken
      <br />
      <br />
      aarzel niet en reageer vandaag nog! Het enige wat u hoeft te doen is u aan
      te melden via onze site en uw CV te uploaden.
    </>
  );

  const imageTextText2p2 = (
    <>
      Na ontvangst van uw gegevens neemt één van onze medewerkers contact met u
      op voor een eerste kennismakingsgesprek. Als het duidelijk is in welke
      richting u het liefst werk zoekt gaan wij voor u aan de slag.
    </>
  );

  const buttonText2 = "Cv Uploaden";
  const buttonLink2 = "/Inschrijven"; // Update with your actual link

  const colorbackground2 = "#D9D9D9";
  const colortext2 = "text-[#284B5A]";

  return (
    <div>
      <Helmet>
        <title>Bouw & Infra</title>
        <meta
          name="description"
          content="Op zoek naar een baan in de bouw of infrastructuur? Wij verbinden vakbekwame medewerkers met topbedrijven in NL en BE. Upload je CV en vind je volgende carrièrestap bij ons."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <Welcome
          videoSource={videoSource}
          heading={welcomeHeading}
          text={welcomeText}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            heading={imageTextHeading}
            buttonText={buttonText1} // Make sure the prop names match
            buttonLink={buttonLink1} // Pass buttonLink if needed
            text={imageTextText}
          />
          <ImageText2
            imageUrl={imageUrl2}
            heading={imageTextHeading2}
            buttonText={buttonText2} // Make sure the prop names match
            buttonLink={buttonLink2} // Pass buttonLink if needed
            text1={imageTextText2}
            text2={imageTextText2p2}
            background={colorbackground2}
            textColor={colortext2}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default BouwInfra;
