import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import Cards from "../../pages/WiezijnWij/Cards";

import backgroundImage from "../../assets/images/Wie zijn Wij Background Image.png";
import imageSrc from "../../assets/images/Wie zijn Wij Image 1.png";
import Footer from "../../components/Footer";
function WiezijnWij() {
  return (
    <div>
      <Helmet>
        <title>Wie Zijn Wij</title>
        <meta
          name="description"
          content="Krijg training met DD-Group over veiligheid, brandpreventie en cybersecurity via Play-IT. Voor interne transport- en veiligheidstrainingen kun je contact met ons opnemen, en wij verbinden je met 'The Knowledge' voor het beste aanbod via onze speciale referentie."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage backgroundImage={backgroundImage} imageSrc={imageSrc} />
        <section>
          <Cards />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default WiezijnWij;
