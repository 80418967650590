import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Cards() {
  const { ref: firstBoxRef, inView: firstBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: secondBoxRef, inView: secondBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: thirdBoxRef, inView: thirdBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const firstBoxControls = useAnimation();
  const secondBoxControls = useAnimation();
  const thirdBoxControls = useAnimation();

  React.useEffect(() => {
    if (firstBoxInView) {
      firstBoxControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      firstBoxControls.start({ x: -100, opacity: 0 });
    }
  }, [firstBoxInView, firstBoxControls]);

  React.useEffect(() => {
    if (secondBoxInView) {
      secondBoxControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      secondBoxControls.start({ x: 100, opacity: 0 });
    }
  }, [secondBoxInView, secondBoxControls]);

  React.useEffect(() => {
    if (thirdBoxInView) {
      thirdBoxControls.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      thirdBoxControls.start({ x: -100, opacity: 0 });
    }
  }, [thirdBoxInView, thirdBoxControls]);

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-[#D9D9D9] ">
      <div
        className="main-container max-w-full h-auto relative mx-auto"
        style={{ maxWidth: "1280px" }}
      >
        <motion.div
          ref={firstBoxRef}
          animate={firstBoxControls}
          initial={{ x: -100, opacity: 0 }}
          className="flex w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl h-auto p-4 flex-col gap-4 justify-center items-center bg-[#d9d9d9] rounded-lg relative border-[#3c7d8c] border-2"
        >
          <div className="flex flex-col gap-4 relative z-[1] text-center">
            <h2 className="text-2xl font-bold text-[#000]">PEOPLE</h2>
            <p className="text-lg text-[#000]">
              De kernwaarden van de ABU en haar gedragscode vormen een
              belangrijke peiler binnen de DD-Group. Deze waarden ondersteunen
              het Maatschappelijk Verantwoord Ondernemen en geven niet alleen
              richting maar liggen ook vast in onze bedrijfscultuur. Wij gaan
              uiterst zorgvuldig om met onze kandidaten en bezoeken geregeld de
              diverse werkplekken om de arbeidsomstandigheden te evalueren.
              Naast bemiddeling bij nieuwe (langdurige) projecten bieden wij
              ook, indien nodig, opleidingen aan via de aan ons gelieerde
              opleidingscentra.
            </p>
          </div>
        </motion.div>

        <motion.div
          ref={secondBoxRef}
          animate={secondBoxControls}
          initial={{ x: 100, opacity: 0 }}
          className="flex w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl h-auto p-4 flex-col gap-4 justify-center items-center bg-[#d9d9d9] rounded-lg relative mt-8 ml-auto border-[#3c7d8c] border-2"
        >
          <div className="flex flex-col gap-4 relative z-[1] text-center">
            <h2 className="text-2xl font-bold text-[#000]">PLANET</h2>
            <p className="text-lg text-[#000]">
              Wij zijn gevestigd in Business Center Blankenburgh, een volledig
              gerenoveerd kantoor, met state of the art faciliteiten.
              Vergaderingen doen wij in eerste instantie allemaal via Teams om
              zo werkverkeer te reduceren. Afval wordt gescheiden en wij
              gebruiken mokken voor koffie en thee. Onze bedrijfsprocessen zijn
              bijna volledig gedigitaliseerd en transparant.
            </p>
          </div>
        </motion.div>

        <motion.div
          ref={thirdBoxRef}
          animate={thirdBoxControls}
          initial={{ x: -100, opacity: 0 }}
          className="flex w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl h-auto p-4 flex-col gap-4 justify-center items-center bg-[#d9d9d9] rounded-lg relative mt-8 border-[#3c7d8c] border-2"
        >
          <div className="flex flex-col gap-4 relative z-[1] text-center">
            <h2 className="text-2xl font-bold text-[#000]">PROFIT</h2>
            <p className="text-lg text-[#000]">
              In samenwerking met onze partners en de diverse sponsorships en
              initiatieven die wij gezamenlijk ondersteunen proberen wij een
              positieve bijdrage te leveren aan lokaal maatschappelijke
              stichtingen en initiatieven. Daarnaast ondersteunen wij diverse
              lokale sportverenigingen en zijn wij hoofdsponsor van een zeer
              talentvolle dameswielerploeg die volgend jaar op UCI niveau gaat
              uitkomen. Buiten de positieve feedback die wij mogen ontvangen
              komen uit deze activiteiten ook weer nieuwe zakelijke contacten
              voort die onze economische positie versterkt.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Cards;
