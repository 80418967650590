import React from "react";

function Linkedin() {
  return (
    <div className="p-8 py-12 mt-16 max-w-full w-full overflow-hidden">
      <iframe
        src="https://widgets.sociablekit.com/instagram-feed/iframe/25460848"
        frameBorder="0"
        width="100%"
        height="1000"
        title="Instagram Feed"
        className="w-full"
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default Linkedin;
