import React from "react";
import { motion } from "framer-motion";
import ImageUrl1 from "../../assets/images/It Solutions World Wide.png";
import ImageUrl2 from "../../assets/images/Logistic Recruiters.png";
import ImageUrl3 from "../../assets/images/Mateck Project Support.png";
import ImageUrl4 from "../../assets/images/Nexxt It.png";
import ImageUrl5 from "../../assets/images/Tanks.png";
import ImageUrl6 from "../../assets/images/Terminal Recruiters.png";
import ImageUrl7 from "../../assets/images/Top Result.png";

const logos = [
  { src: ImageUrl2, link: "https://www.logisticrecruiters.nl/" },
  { src: ImageUrl3, link: "https://www.mateck.nl/" },
  { src: ImageUrl4, link: "https://nexxt-it.nl/" },
  { src: ImageUrl5, link: "https://itanks.eu/?lang=en" },
  { src: ImageUrl6, link: "https://www.terminalrecruiters.nl/" },

  { src: ImageUrl7, link: "https://top-result.nl/" },
  { src: ImageUrl1, link: "https://www.itsolutionsworldwide.com/" },
];

const LogoSlider = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-8 py-12"
      style={{
        background:
          "linear-gradient(9deg, rgba(40, 75, 90, 0.20) -2.93%, rgba(89, 161, 193, 0.20) 93.32%)",
      }}
    >
      <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold w-full lg:w-1/2 ml-0 lg:ml-8 text-[#284b5a] text-center lg:text-left">
        Partners
      </h2>
      <div className="overflow-hidden relative">
        <motion.div
          className="flex space-x-8 animate-marquee"
          animate={{ x: ["0%", "-100%"] }}
          transition={{
            repeat: Infinity,
            ease: "linear",
            duration: 1, // Faster speed with shorter duration
          }}
        >
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo.src}
                alt={`Partner logo ${index + 1}`}
                className="w-[36] h-36 md:w-48 md:h-48 lg:w-60 lg:h-60 object-contain"
              />
            </a>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default LogoSlider;
