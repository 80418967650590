import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TextImage = ({
  heading,
  text,
  imageUrl,
  backgroundColor = "#284B5A",
  textColor = "#fff",
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.5, // Trigger animation as soon as the component enters the viewport
  });

  return (
    <div style={{ backgroundColor }}>
      <div
        style={{
          minHeight: "300px", // Ensuring the container has some height
          maxWidth: "1310px", // Setting maxWidth to 1310px
          margin: "0 auto", // Centering the container
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center content horizontally
          textAlign: "center", // Center text alignment
        }}
        ref={ref}
        className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
      >
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.6 }}
          style={{ width: "100%" }} // Make sure the motion div takes full width
        >
          <h2
            className="text-3xl lg:text-3xl font-semibold mb-4"
            style={{ color: textColor, fontWeight: "bold" }}
          >
            {heading}
          </h2>
          <p className="text-lg lg:text-lg mb-6" style={{ color: textColor }}>
            {text}
          </p>
        </motion.div>
        <motion.img
          src={imageUrl}
          alt="Image"
          className="w-[477px] h-[413px] object-contain mt-6" // Add margin-top for spacing between text and image
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.3 }} // Added delay for a staggered effect
        />
      </div>
    </div>
  );
};

export default TextImage;
